export const defaultDashboard = [
  {
    header: 'Dashboards',
    icon: 'HomeIcon',
    children: [
      {
        title: 'Analytics',
        route: 'dashboard-analytics',
        icon: 'PieChartIcon',
      },
      {
        title: 'Overview',
        route: 'dashboard-overview',
        icon: 'LayoutIcon',
      },
    ],
  },
]

export const CompanyOrProjectAdminDashboard = [
  {
    header: 'Dashboards',
    icon: 'HomeIcon',
    children: [
      {
        title: 'Overview',
        route: 'dashboard-detail-view',
        icon: 'LayoutIcon',
      },
    ],
  },
]
